import React from "react";
import PropTypes from 'prop-types';
import cx from "classnames";
import {DateField} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import moment from "moment";

const EndAtField = (props) => {
  const {record, source, classes: c, ...restProps} = props;
  const endAt = moment(record[source]);
  let className;
  if (!record['active']) {
    className = 'disabled';
  }
  else {
    className = endAt.isAfter() ? 'active' : 'passed';
  }
  return <DateField
    { ...restProps }
    record={record}
    source={source}
    showTime
    locales='uk-UA'
    className={ cx(c[className]) }
  />
};

EndAtField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

EndAtField.defaultProps = {
  record: {},
};

const styles = theme => ({
  active: {
    color: '#25bd25',
  },
  passed: {
    color: '#cb5d5d',
  },
  disabled: {
    color: '#999',
  },
});

const EnhancedEndAtField = withStyles(styles)(EndAtField);

EnhancedEndAtField.defaultProps = {
  addLabel: true,
};

export default EnhancedEndAtField;